#App {
  overflow-y: scroll;
  font-family: 'Rubik', sans-serif;
  overflow-x: hidden;
  /* Give app full page to work with */
  height: 100vh;
  width: 100vw;
}

#page-wrap {
  text-align: center;
}

/* Individual item */
.bm-item {
  display: inline-block;
  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

.bm-item:focus {
  outline: none;
}

/* The rest copied directly from react-burger-menu docs */
.bm-morph-shape {
  fill: #373a47;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 24px;
  height: 24px;
  left: 1.5rem;
  top: 1.5rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #888;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-menu a {
  color: #b8b7ad;
}

.bm-menu a:hover,
a:focus {
  color: #c94e50;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

.bm-item-list a {
  padding: 0.8em;
}

.bm-item-list a span {
  margin-left: 10px;
  font-weight: 700;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

#side-bar h2 {
  margin: 0 auto;
  padding: 2em 1em;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
#side-bar h2 i {
  margin-left: 0.2em;
}
#side-bar h2 span {
  font-size: 1.6em;
  font-weight: 700;
}
#side-bar a {
  padding: 1em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
#side-bar a span {
  letter-spacing: 1px;
  font-weight: 400;
}

#side-bar a:hover,
#side-bar a:focus {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #b8b7ad;
}

@media (min-width: 768px) {
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
}
